export const environment = {
  production: true,
  test: false,
  firebase: {
    apiKey: 'AIzaSyAAKNVFGAdu8TUqs069YOyGXblLpXhkgJI',
    authDomain: 'xdev-develop.firebaseapp.com',
    projectId: 'xdev-develop',
    storageBucket: 'xdev-develop.appspot.com',
    messagingSenderId: '736161127579',
    appId: '1:736161127579:web:ff9eccc8d887bf510f3a3e',
    measurementId: 'G-W2NLMJQ9BZ',
  },
  firebaseFunctionsUrl: 'https://europe-west1-xdev-develop.cloudfunctions.net/',
  monthlySubscription: {
    price: 'price_1LAsgxI0uyVAlrPMDWntz89S',
    product: 'prod_Lsdy1bFjEHZOZE',
  },
  yearlySubscription: {
    price: 'price_1NDlklI0uyVAlrPMqhqrMtQi',
    product: 'prod_Lsdy1bFjEHZOZE',
  },
  monSuiviDietPlus: {
    urlApi: 'https://monsuividietplus.com/version-test/api/1.1/wf',
  },
};
