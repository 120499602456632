import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { BillParams } from '../domain/bills/bill-params';
import { DietitianId } from '../domain/dietitian';
import { Picture } from '../domain/picture/picture';
import { PictureRepository } from './picture_repository';

export interface BillParamsSchema {
  logoUrl?: string | null;
  logoPath?: string | null;
  logoPosition?: 'left' | 'center' | 'right' | null;
  logoSize?: 'small' | 'medium' | 'large' | null;
  socialReason?: string | null;
  registrationNumber?: string | null;
  address?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  tax: 'with' | 'without';
  taxNumber?: string | null;
  taxRate?: number | null;
  noTaxMention: boolean;
  footer?: string | null;
  previewUrl?: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

@Injectable()
export class BillParamsRepository {
  constructor(
    private firestore: AngularFirestore,
    private pictureRepository: PictureRepository,
  ) {
    // Nada
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<BillParamsSchema>('bill_params', queryFn);
  }

  private toSchema(billParams: BillParams): BillParamsSchema {
    return {
      logoUrl: billParams.logo?.url ?? null,
      logoPath: billParams.logo?.path ?? null,
      logoPosition: billParams.logoPosition ?? null,
      logoSize: billParams.logoSize ?? null,
      socialReason: billParams.socialReason ?? null,
      registrationNumber: billParams.registrationNumber ?? null,
      address: billParams.address ?? null,
      zip: billParams.zip ?? null,
      city: billParams.city ?? null,
      country: billParams.country ?? null,
      phoneNumber: billParams.phoneNumber ?? null,
      email: billParams.email ?? null,
      tax: billParams.tax,
      taxNumber: billParams.taxNumber ?? null,
      taxRate: billParams.taxRate ?? null,
      noTaxMention: billParams.noTaxMention,
      footer: billParams.footer ?? null,
      previewUrl: billParams.previewUrl ?? null,
      createdAt: billParams.createdAt
        ? Timestamp.fromDate(billParams.createdAt)
        : Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
  }

  private fromSchema(
    schema: BillParamsSchema,
    dietitianId: string,
    id?: string,
  ): BillParams {
    return BillParams.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        logo:
          schema.logoUrl && schema.logoPath
            ? Picture.create({
                url: schema.logoUrl,
                path: schema.logoPath,
              })
            : undefined,
        logoPosition: schema.logoPosition ?? undefined,
        logoSize: schema.logoSize ?? undefined,
        socialReason: schema.socialReason ?? undefined,
        registrationNumber: schema.registrationNumber ?? undefined,
        address: schema.address ?? undefined,
        zip: schema.zip ?? undefined,
        city: schema.city ?? undefined,
        country: schema.country ?? undefined,
        phoneNumber: schema.phoneNumber ?? undefined,
        email: schema.email ?? undefined,
        tax: schema.tax,
        taxNumber: schema.taxNumber ?? undefined,
        taxRate: schema.taxRate ?? undefined,
        noTaxMention: schema.noTaxMention,
        footer: schema.footer ?? undefined,
        previewUrl: schema.previewUrl ?? undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
      },
      new UniqueEntityID(id),
    );
  }

  async save(billParams: BillParams, create = false): Promise<BillParams> {
    const dietitianId = billParams.dietitianId.id.toString();
    const schema = this.toSchema(billParams);
    let oldBillParams = undefined;
    if (!create) {
      oldBillParams = await this.load(billParams.dietitianId.id.toString());
    }
    if (billParams.logo) {
      if (
        !oldBillParams ||
        !oldBillParams.logo ||
        !oldBillParams.logo.equals(billParams.logo)
      ) {
        if (oldBillParams && oldBillParams.logo) {
          await this.pictureRepository.delete(oldBillParams.logo);
        }
        const savedLogo = await this.pictureRepository.save(billParams.logo);
        schema.logoUrl = savedLogo.url;
        schema.logoPath = savedLogo.path;
        schema.logoPosition = schema.logoPosition
          ? schema.logoPosition
          : 'left';
        schema.logoSize = schema.logoSize ? schema.logoSize : 'medium';
      }
    }
    await this.collection(dietitianId)
      .doc(oldBillParams ? oldBillParams.id.toString() : undefined)
      .set(schema);
    return this.fromSchema(schema, dietitianId);
  }

  async load(dietitianId: string): Promise<BillParams> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (qry) => qry.limit(1)).get(),
    );
    if (!snap.docs || snap.docs.length == 0) {
      const newBillParams = BillParams.create({
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        tax: 'with',
        noTaxMention: false,
      });
      return await this.save(newBillParams, true);
    } else {
      const schema = snap.docs[0].data() as BillParamsSchema;
      return this.fromSchema(schema, dietitianId, snap.docs[0].id);
    }
  }
}
