import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { delay, firstValueFrom, Observable } from 'rxjs';
import PaginationResult from 'src/app/core/domain/pagination-result';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { UserId } from '../../auth/domain/user';
import {
  AccountStatus,
  Gender,
  Patient,
  PatientProps,
} from '../domain/patient';
import { PatientNotFoundException } from '../domain/patient_exceptions';

interface PatientSchema {
  userId: string | null;
  dietId: string;
  firstName: string;
  lastName: string;
  email: string;
  goals?: string[];
  phoneNumber: string;
  nextAppointment: Timestamp | null;
  birthday: Timestamp | null;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
  archivedAt: Timestamp | string | null;
  groupId: string[] | null;
  lastActiveDate: Timestamp | null;
  gender: Gender | null;
  avatarUrl: string | null;
  consultationReason: string | null;
  accountStatus: AccountStatus;
  isActive: boolean;
  unreadDiariesCount: number;
  lastUnreadDiaryCreatedAt: Timestamp | null;
  msdpId: string | null;
}

interface PatientCounter {
  activated: number;
  archived: number;
}

@Injectable()
export class PatientRepository {
  private readonly searchDietitianPatientsFromCloud: (
    data: unknown,
  ) => Observable<PaginationResult<{ [key: string]: unknown }>>;
  private readonly searchPatientsForPatientGroupFromCloud: (
    data: unknown,
  ) => Observable<PaginationResult<{ [key: string]: unknown }>>;
  private savePatientToAlgolia: (data: unknown) => Observable<void>;
  private saveAllPatientsToAlgolia: (data: unknown) => Observable<void>;
  private createPatientInvitationNotificationFromCloud: (
    data: unknown,
  ) => Observable<void>;
  public registerPatientForSurvey: (
    data: unknown,
  ) => Observable<string | undefined>;
  public loginPatientForSurvey: (data: unknown) => Observable<string>;

  private static readonly PATIENT_INDEX = 'patients';
  private static readonly PATIENT_CREATED_AT_DESC_INDEX =
    'patients_createdAt_desc';
  private static readonly PATIENT_LAST_ACTIVE_DATE_DESC_INDEX =
    'patients_lastActiveDate_desc';

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    this.searchDietitianPatientsFromCloud = this.functions.httpsCallable<
      unknown,
      PaginationResult<{ [key: string]: unknown }>
    >('patient-searchDietitianPatients');
    this.searchPatientsForPatientGroupFromCloud = this.functions.httpsCallable<
      unknown,
      PaginationResult<{ [key: string]: unknown }>
    >('patient-searchPatientsForPatientGroup');
    this.savePatientToAlgolia = this.functions.httpsCallable<unknown, void>(
      'patient-savePatientInAgolia',
    );
    this.saveAllPatientsToAlgolia = this.functions.httpsCallable<unknown, void>(
      'patient-saveAllPatientsInAgolia',
    );
    this.createPatientInvitationNotificationFromCloud =
      this.functions.httpsCallable<unknown, void>(
        'patient-createPatientInvitationNotification',
      );
    this.registerPatientForSurvey = this.functions.httpsCallable<
      unknown,
      string | undefined
    >('patient-registerPatientForSurvey');
    this.loginPatientForSurvey = this.functions.httpsCallable<unknown, string>(
      'patient-loginPatientForSurvey',
    );
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<PatientSchema>('patients', queryFn);
  }

  private collectionProps(queryFn?: QueryFn) {
    return this.firestore.collection<PatientProps>('patients', queryFn);
  }

  toSchema(patient: Patient): PatientSchema {
    return <PatientSchema>{
      userId: patient.userId?.id.toString() ?? null,
      dietId: patient.dietId,
      firstName: patient.firstName,
      lastName: patient.lastName,
      email: patient.email,
      phoneNumber: patient.phoneNumber ?? null,
      nextAppointment:
        patient.nextAppointment !== undefined
          ? Timestamp.fromDate(patient.nextAppointment)
          : null,
      birthday:
        patient.birthday !== undefined
          ? Timestamp.fromDate(patient.birthday)
          : null,
      goals: patient.goals ?? null,
      createdAt:
        patient.createdAt !== undefined
          ? Timestamp.fromDate(patient.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      // algolia doesn't handle null or undefined
      archivedAt:
        patient.archivedAt !== undefined
          ? Timestamp.fromDate(patient.archivedAt)
          : 'null',
      // algolia doesn't handle null or undefined
      groupId: patient.groupId ?? 'null',
      lastActiveDate:
        patient.lastActiveDate !== undefined
          ? Timestamp.fromDate(patient.lastActiveDate)
          : null,
      gender: patient.gender ?? null,
      avatarUrl: patient.avatarUrl ?? null,
      consultationReason: patient.consultationReason ?? null,
      accountStatus: patient.accountStatus,
      isActive: patient.isActive,
      unreadDiariesCount: patient.unreadDiariesCount,
      lastUnreadDiaryCreatedAt:
        patient.lastUnreadDiaryCreatedAt !== undefined
          ? Timestamp.fromDate(patient.lastUnreadDiaryCreatedAt)
          : null,
      msdpId: patient.msdpId ?? null,
    };
  }

  /**
   * Fix timestamp for algolia
   */
  fixBrokenTimestamp(timestamp: unknown): Timestamp | null {
    if (timestamp instanceof Timestamp || timestamp === null) {
      return timestamp;
    }
    if (typeof timestamp === 'number') {
      return Timestamp.fromMillis(timestamp);
    }
    if (typeof timestamp === 'object') {
      const tt = timestamp as Timestamp;
      return new Timestamp(tt.seconds, tt.nanoseconds);
    }

    return null;
  }

  /**
   * Fix schema timestamps for algolia
   */
  fixSchemaBrokenTimestamp(schema: PatientSchema): PatientSchema {
    schema.nextAppointment = this.fixBrokenTimestamp(schema.nextAppointment);
    schema.birthday = this.fixBrokenTimestamp(schema.birthday);
    schema.createdAt = this.fixBrokenTimestamp(schema.createdAt);
    schema.updatedAt = this.fixBrokenTimestamp(schema.updatedAt);
    schema.archivedAt = this.fixBrokenTimestamp(schema.archivedAt);
    schema.lastActiveDate = this.fixBrokenTimestamp(schema.lastActiveDate);
    schema.lastUnreadDiaryCreatedAt = this.fixBrokenTimestamp(
      schema.lastUnreadDiaryCreatedAt,
    );

    return schema;
  }

  fromSchema(schema: PatientSchema, id: string): Patient {
    return Patient.create(
      {
        firstName: schema.firstName,
        lastName: schema.lastName,
        dietId: schema.dietId,
        email: schema.email,
        lastActiveDate: schema.lastActiveDate?.toDate(),
        nextAppointment: schema.nextAppointment?.toDate(),
        birthday: schema.birthday?.toDate(),
        updatedAt: schema.updatedAt?.toDate(),
        createdAt: schema.createdAt?.toDate(),
        goals: schema.goals ?? undefined,
        archivedAt:
          schema.archivedAt instanceof Timestamp
            ? schema.archivedAt?.toDate()
            : undefined,
        avatarUrl: schema.avatarUrl ?? undefined,
        consultationReason: schema.consultationReason ?? undefined,
        gender: schema.gender ?? undefined,
        isActive: schema.isActive,
        phoneNumber: schema.phoneNumber,
        status: schema.accountStatus,
        userId: schema.userId
          ? UserId.create(new UniqueEntityID(schema.userId))
          : undefined,
        // algolia doesn't handle null or undefined
        groupId: Array.isArray(schema.groupId) ? schema.groupId : undefined,
        unreadDiariesCount: schema.unreadDiariesCount ?? 0,
        lastUnreadDiaryCreatedAt:
          schema.lastUnreadDiaryCreatedAt?.toDate() ?? undefined,
        msdpId: schema.msdpId ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  fromMap(map: { [key: string]: unknown }): Patient {
    const getDate = (data: unknown) =>
      typeof data === 'string' ? new Date(data) : undefined;

    return Patient.create(
      {
        firstName: map['firstName'] as string,
        lastName: map['lastName'] as string,
        dietId: map['dietId'] as string,
        email: map['email'] as string,
        goals: map['goals'] as string[] | undefined,
        lastActiveDate: getDate(map['lastActiveDate']),
        nextAppointment: getDate(map['nextAppointment']),
        birthday: getDate(map['birthday']),
        updatedAt: getDate(map['updatedAt']),
        createdAt: getDate(map['createdAt']),
        archivedAt: getDate(map['archivedAt']),
        avatarUrl: map['avatarUrl'] as string | undefined,
        consultationReason: map['consultationReason'] as string | undefined,
        gender:
          typeof map['gender'] === 'string'
            ? Gender[map['gender'] as keyof typeof Gender]
            : undefined,
        isActive:
          typeof map['isActive'] === 'boolean' ? map['isActive'] : false,
        phoneNumber:
          typeof map['phoneNumber'] === 'string'
            ? map['phoneNumber']
            : undefined,
        status:
          AccountStatus[map['accountStatus'] as keyof typeof AccountStatus],
        userId:
          typeof map['userId'] === 'string'
            ? UserId.create(new UniqueEntityID(map['userId']))
            : undefined,
        // algolia doesn't handle null or undefined
        groupId: Array.isArray(map['groupId']) ? map['groupId'] : undefined,
        unreadDiariesCount:
          typeof map['unreadDiariesCount'] === 'number'
            ? map['unreadDiariesCount']
            : 0,
        lastUnreadDiaryCreatedAt: getDate(map['lastUnreadDiaryCreatedAt']),
      },
      new UniqueEntityID(map['id'] as string),
    );
  }

  async count(dietitianId: string): Promise<PatientCounter> {
    const refColl = this.collection((ref) =>
      ref.where('dietId', '==', dietitianId),
    );
    const snap = await firstValueFrom(refColl.get());
    const docs = snap.docs;
    const activated = docs
      .map((d) => d.data())
      .filter((p) => !p.archivedAt || p.archivedAt === 'null').length;
    const archived = docs
      .map((d) => d.data())
      .filter((p) => p.archivedAt && p.archivedAt !== 'null').length;
    return { activated, archived };
  }

  async load(patientId: string): Promise<Patient> {
    return firstValueFrom(
      this.collection().doc(patientId).get().pipe(delay(200)),
    )
      .then((snap) => {
        const data = snap.data();
        if (!data) throw new PatientNotFoundException();
        return this.fromSchema(data, snap.id);
      })
      .catch((e) => {
        console.error('PatientRepo.error', e);
        return Promise.reject(e);
      });
  }

  async existsForDietitianAndPhoneNumber(
    dietitianId: string,
    phoneNumber: string,
    patientId?: string,
  ): Promise<boolean> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietId', '==', dietitianId)
          .where('phoneNumber', '==', phoneNumber),
      ).get(),
    );

    return snap.size !== 0 && !snap.docs.some((doc) => doc.id == patientId);
  }

  async save(patient: Patient, waitForSync = true): Promise<Patient> {
    const schema = this.toSchema(patient);

    await this.collection().doc(patient.id.toString()).update(schema);

    const sync = firstValueFrom(
      this.savePatientToAlgolia({
        patientId: patient.patientId.id.toString(),
        index: PatientRepository.PATIENT_INDEX,
      }),
    ).catch();

    if (waitForSync) {
      await sync;
    }

    return this.fromSchema(schema, patient.patientId.id.toString());
  }

  // Fix bug of erase userId...
  async update(
    patientId: string,
    props: PatientProps,
    waitForSync = true,
  ): Promise<void> {
    await this.collectionProps().doc(patientId).update(props);

    const sync = firstValueFrom(
      this.savePatientToAlgolia({
        patientId: patientId,
        index: PatientRepository.PATIENT_INDEX,
      }),
    ).catch();

    if (waitForSync) {
      await sync;
    }
  }

  async create(patient: Patient, waitForSync = true): Promise<Patient> {
    const schema = this.toSchema(patient);

    const createdPatient = await this.collection().add(schema);

    const sync = firstValueFrom(
      this.savePatientToAlgolia({
        patientId: createdPatient.id,
        index: PatientRepository.PATIENT_INDEX,
      }),
    ).catch();

    if (waitForSync) {
      await sync;
    }

    return this.fromSchema(schema, createdPatient.id);
  }

  async createPatientInvitationNotification(patient: Patient): Promise<void> {
    await firstValueFrom(
      this.createPatientInvitationNotificationFromCloud({
        patientId: patient.patientId.id.toString(),
      }),
    ).catch();
  }

  async getDietitianPatients(
    dietitianId: string,
    groupId: string | null,
    includeArchived = false,
  ) {
    const snap = await firstValueFrom(
      this.collection((ref) => {
        let query = ref.where('dietId', '==', dietitianId);

        if (groupId) {
          query = query.where('groupId', 'array-contains', groupId);
        }

        if (!includeArchived) {
          query = query.where('archivedAt', '==', 'null');
        }
        return query;
      }).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async searchDietitianPatients(
    dietitianId: string,
    query: string,
    groupId: string | null,
    archived: boolean,
    resultsPerPage: number,
    page: number,
    sort: 'createdAt' | 'updatedAt' | 'lastActiveDate' = 'updatedAt',
  ): Promise<PaginationResult<Patient>> {
    let index = PatientRepository.PATIENT_INDEX;
    if (sort === 'createdAt') {
      index = PatientRepository.PATIENT_CREATED_AT_DESC_INDEX;
    } else if (sort === 'lastActiveDate') {
      index = PatientRepository.PATIENT_LAST_ACTIVE_DATE_DESC_INDEX;
    }
    const results = await firstValueFrom(
      this.searchDietitianPatientsFromCloud({
        dietitianId,
        query,
        index,
        groupId,
        archived,
        resultsPerPage,
        page,
      }),
    );

    const ret = {
      ...results,
      results: results.results.map(this.fromMap),
    };

    return ret;
  }

  async getDietitianPatientsWithUnreadDiaries(
    dietitianId: string,
    groupId: string | null,
    archived: boolean,
    maxAgeSeconds = 0,
  ) {
    const minDate =
      maxAgeSeconds > 0
        ? moment().add(-maxAgeSeconds, 'second').toDate()
        : undefined;
    const snap = await firstValueFrom(
      this.collection((ref) => {
        let query = ref.where('dietId', '==', dietitianId);

        if (groupId != null) {
          query = query.where('groupId', 'array-contains', groupId);
        }

        if (minDate) {
          query = query.where('lastUnreadDiaryCreatedAt', '>=', minDate);
        }

        query = query.orderBy('lastUnreadDiaryCreatedAt', 'desc');

        return query;
      }).get(),
    );

    let docs = snap.docs;
    if (archived) {
      docs = docs.filter((d) => d.data().archivedAt != 'null');
    } else {
      docs = docs.filter((d) => d.data().archivedAt == 'null');
    }

    return docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async getPatientGroupPatients(
    dietitianId: string,
    groupId: string,
    includeArchived = true,
  ): Promise<Patient[]> {
    const snap = await firstValueFrom(
      this.collection((ref) => {
        const query = includeArchived
          ? ref
          : ref.where('archivedAt', '==', 'null');

        return query
          .where('dietId', '==', dietitianId)
          .where('groupId', 'array-contains', groupId)
          .orderBy('lastName')
          .orderBy('firstName');
      }).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async searchPatientsForPatientGroup(
    dietitianId: string,
    groupId: string,
    includeArchived: boolean,
    query: string,
    resultsPerPage: number,
    page: number,
  ): Promise<PaginationResult<Patient>> {
    const results = await firstValueFrom(
      this.searchPatientsForPatientGroupFromCloud({
        dietitianId,
        groupId,
        includeArchived,
        query,
        index: PatientRepository.PATIENT_INDEX,
        resultsPerPage,
        page,
      }),
    );

    return {
      ...results,
      results: results.results.map(this.fromMap),
    };
  }

  async updateBatch(patients: Patient[]) {
    const batch = this.firestore.firestore.batch();
    for (const patient of patients) {
      const schema = this.toSchema(patient);
      const ref = this.collection().doc(patient.id.toString()).ref;
      batch.set(ref, schema);
    }
    await batch.commit();
    const sync = firstValueFrom(
      this.saveAllPatientsToAlgolia({
        patientIds: patients.map((p) => p.id.toString()),
        index: PatientRepository.PATIENT_INDEX,
      }),
    ).catch();
    await sync;
  }
}
