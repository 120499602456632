import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { BillLineDescription } from './bill-line-description';

export interface BillLineProps {
  description: BillLineDescription | undefined;
  comment?: string | undefined;
  amount: number | undefined;
  createdAt?: Date | undefined;
  updatedAt?: Date | undefined;
}

export class BillLine extends Entity<BillLineProps> {
  get description(): BillLineDescription | undefined {
    return this.props.description;
  }

  set description(value: BillLineDescription | undefined) {
    this.props.description = value;
  }

  get comment(): string | undefined {
    return this.props.comment;
  }

  set comment(value: string | undefined) {
    this.props.comment = value;
  }

  get amount(): number | undefined {
    return this.props.amount;
  }

  set amount(value: number | undefined) {
    this.props.amount = value;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: BillLineProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: BillLineProps): BillLine {
    return BillLine.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: BillLineProps, id?: UniqueEntityID): BillLine {
    return new BillLine(props, id);
  }
}
