import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { PatientId } from '../../../patient/domain/patient';
import { DietitianId } from '../dietitian';
import { BillLine } from './bill-line';
import { BillStatus } from './bill-status';

export interface BillProps {
  dietitianId: DietitianId;
  patientId: PatientId;
  referencePrefix?: string | undefined;
  reference?: number | undefined;
  billedAt: Date | undefined;
  patientFullname: string;
  lines: BillLine[];
  amountExclTax: number;
  amountInclTax: number;
  amountTax: number;
  rateTax: number;
  withTax: boolean;
  draftedAt?: Date | undefined;
  emittedAt?: Date | undefined;
  paidAt?: Date | undefined;
  createdAt?: Date | undefined;
  updatedAt?: Date | undefined;
  archived: boolean;
  archivedAt?: Date | undefined;
  status?: BillStatus | undefined;
  url?: string | undefined;
}

export class BillId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): BillId {
    return new BillId(id);
  }
}

export class Bill extends Entity<BillProps> {
  get billId(): BillId {
    return BillId.create(this.id);
  }

  get dietitianId(): DietitianId | undefined {
    return this.props.dietitianId ?? undefined;
  }

  get patientId(): PatientId {
    return this.props.patientId;
  }

  set patientId(value: PatientId) {
    this.props.patientId = value;
  }

  get referencePrefix(): string | undefined {
    return this.props.referencePrefix;
  }

  set referencePrefix(value: string | undefined) {
    this.props.referencePrefix = value;
  }

  get reference(): number | undefined {
    return this.props.reference;
  }

  get billedAt(): Date | undefined {
    return this.props.billedAt;
  }

  set billedAt(value: Date | undefined) {
    this.props.billedAt = value;
  }

  get patientFullname(): string {
    return this.props.patientFullname;
  }

  set patientFullname(value: string) {
    this.props.patientFullname = value;
  }

  get lines(): BillLine[] {
    return this.props.lines;
  }

  get amountExclTax(): number {
    return this.props.amountExclTax;
  }

  get amountInclTax(): number {
    return this.props.amountInclTax;
  }

  get amountTax(): number {
    return this.props.amountTax;
  }

  get rateTax(): number {
    return this.props.rateTax;
  }

  get withTax(): boolean {
    return this.props.withTax;
  }

  get draftedAt(): Date | undefined {
    return this.props.draftedAt;
  }

  set draftedAt(value: Date | undefined) {
    this.props.draftedAt = value;
  }

  get emittedAt(): Date | undefined {
    return this.props.emittedAt;
  }

  set emittedAt(value: Date | undefined) {
    this.props.emittedAt = value;
  }

  get paidAt(): Date | undefined {
    return this.props.paidAt;
  }

  set paidAt(value: Date | undefined) {
    this.props.paidAt = value;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get archived(): boolean {
    return this.props.archived;
  }

  get archivedAt(): Date | undefined {
    return this.props.archivedAt;
  }

  get status(): BillStatus | undefined {
    return this.props.status;
  }

  set status(value: BillStatus | undefined) {
    this.props.status = value;
  }

  get url(): string | undefined {
    return this.props.url;
  }

  set url(value: string | undefined) {
    this.props.url = value;
  }

  private constructor(props: BillProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: BillProps): Bill {
    return Bill.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  archive(): Bill {
    return this.copyWith({ archivedAt: new Date() } as BillProps);
  }

  restore(): Bill {
    return this.copyWith({ archivedAt: undefined } as BillProps);
  }

  public static create(props: BillProps, id?: UniqueEntityID): Bill {
    return new Bill(props, id);
  }
}
