import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { DietitianId } from '../dietitian';

export interface BillLineDescriptionProps {
  dietitianId: DietitianId;
  label: string;
  amount?: number | undefined;
  createdAt?: Date | undefined;
  updatedAt?: Date | undefined;
}

export class BillLineDescriptionId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): BillLineDescriptionId {
    return new BillLineDescriptionId(id);
  }
}

export class BillLineDescription extends Entity<BillLineDescriptionProps> {
  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  set dietitianId(value: DietitianId) {
    this.props.dietitianId = value;
  }

  public get label(): string {
    return this.props.label;
  }

  public set label(value: string) {
    this.props.label = value;
  }

  public get amount(): number | undefined {
    return this.props.amount;
  }

  public set amount(value: number | undefined) {
    this.props.amount = value;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: BillLineDescriptionProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: BillLineDescriptionProps): BillLineDescription {
    return BillLineDescription.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: BillLineDescriptionProps,
    id?: UniqueEntityID,
  ): BillLineDescription {
    return new BillLineDescription(props, id);
  }
}
